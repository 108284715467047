import { createConsumer } from "@anycable/web";
import * as Sentry from "@sentry/react";
import { notification } from "antd";
import { isSameWeek, startOfWeek } from "date-fns";
import React, { useEffect, useState } from "react";
import GlobalStoreContext from "./GlobalStoreContext";
import Helper from "./Helper";
import { Mixpanel } from "./Mixpanel";
import PageContentType from "./PageContentType";
import Permission from "./Permission";
import Weather from "./Weather";
import { CalendarEvent } from "./components/Calendar/projectCalendarTypes";
import { globalAppConfig } from "./config";
import PlotAPI from "./plotAPI";
import TeamInfo from "./teamInfo";
import UserInfo from "./userInfo";

function GlobalStoreProvider(props: { children: React.ReactNode }) {
    const [pageContentType, setPageContentType] = useState<PageContentType | null>(null); // Type of content being shown in the content area

    const [userInfo, _setUserInfo] = useState<null | UserInfo>(null); // User info for the current user
    const [gottenUserInfo, setGottenUserInfo] = useState(false);
    const [userPic, _setUserPic] = useState<null | string>(null); // Profile pic for the current user
    const [loggedIn, setLoggedIn] = useState(false); // Whether the user is logged in
    const [usersTeams, setUsersTeams] = useState<null | TeamInfo[]>(null); // Teams that a user belongs to

    const [currentTeam, _setCurrentTeam] = useState<null | TeamInfo>(null); // Current Team that the user is acting as
    const [currentTeamsProjects, setCurrentTeamsProjects] = useState<null | {
        member_of: any[];
        not_member_of: any[];
        projects: any[];
        recent_projects: any[];
    }>(null); // Projects that the current Team belongs to (claimed and joined)

    const [currentProjectToken, _setCurrentProjectToken] = useState<null | string>(null); // Token of the current Project being viewed
    const [currentProjectInfo, _setCurrentProjectInfo] = useState<null | any>(null); // Project Info of the current Project being viewed

    const [currentViewedTeamInfo, _setCurrentViewedTeamInfo] = useState<TeamInfo | null>(null);

    const [windowSize, setWindowSize] = useState({ width: 0, height: 0 });
    const [isMobile, setIsMobile] = useState(false);

    const [cableApp, setCableApp] = useState<null | actionCable.Cable>(null);

    const [dashboardNotificationsChannel, setDashboardNotificationsChannel] = useState<null | any>(null);
    const [mapChannel, setMapChannel] = useState<null | any>(null);
    const [deliveriesChannel, setDeliveriesChannel] = useState<
        | null
        | (actionCable.Channel & {
              received: (data: any) => void;
          })[]
    >(null);
    const [broadcastsChannel, setBroadcastsChannel] = useState<null | any>(null);
    const [newBroadcastMessage, setNewBroadcastMessage] = useState<boolean>(false);
    const [currentProjectWeather, setCurrentProjectWeather] = useState<null | any>(null);
    const [projectMapLayers, setProjectMapLayers] = useState<any>(null);
    const [currentAppVersion, _setCurrentAppVersion] = useState<null | string>(null);
    const [thisWeeksEvents, setThisWeeksEvents] = React.useState<{ day_index: number; event: CalendarEvent }[]>([]);
    const [activeSidebarKey, setActiveSidebarKey] = useState("portal");
    const [collapseSidebar, setCollapseSidebar] = useState(false);
    const [showNotificationsDrawer, setShowNotificationsDrawer] = useState(false);

    // Run once at beginning
    useEffect(() => {
        setGottenUserInfo(false);
        PlotAPI.getCurrentUserInfo().then((response) => {
            if (response) {
                _setUserInfo(new UserInfo(response));
            }

            setLoggedIn(response ? true : false);
            setGottenUserInfo(true);
            setCurrentAppVersion();
            if (response) {
                Mixpanel.identify(response.id);
                Mixpanel.track("Accessed a Page", {
                    type:
                        pageContentType == PageContentType.PROJECT
                            ? "PROJECT"
                            : pageContentType == PageContentType.TEAM
                            ? "TEAM"
                            : pageContentType == PageContentType.UNCLAIMED_TEAM
                            ? "UNCLAIMED TEAM"
                            : pageContentType == PageContentType.DELIVERY
                            ? "DELIVERY"
                            : "UNKNOWN",
                    project_token: currentProjectToken,
                    team_subdomain: window.location.hostname.split(".")[0],
                });

                PlotAPI.getCurrentUserPic().then((response) => {
                    _setUserPic(response);
                });

                setUserTheme(response.user_preferences.theme);
            }
        });

        window.addEventListener("resize", handleWindowSizeChange);
        handleWindowSizeChange();
        createActionCable();

        return () => {
            cableApp?.disconnect();

            dashboardNotificationsChannel?.unsubscribe();

            mapChannel?.unsubscribe();
            deliveriesChannel?.map((item) => item?.unsubscribe());
            broadcastsChannel?.unsubscribe();
        };
    }, []);

    // ActionCable setup for user websocket
    const createActionCable = () => {
        // TODO: This uses the AnyCable compatibility method, not its full
        // implementation.
        setCableApp(createConsumer());
    };

    useEffect(() => {
        if (cableApp && currentProjectToken) {
            setMapChannel(
                cableApp.subscriptions.create({
                    channel: "MapChannel",
                    project_unique_token: currentProjectToken,
                })
            );
            setDashboardNotificationsChannel(
                cableApp.subscriptions.create({
                    channel: "DashboardNotificationsChannel",
                })
            );
            setBroadcastsChannel(
                cableApp.subscriptions.create(
                    {
                        channel: "BroadcastsChannel",
                        project_unique_token: currentProjectToken,
                    },
                    {
                        received: (data) => {
                            setNewBroadcastMessage(true);
                            notification.warning({
                                message: `New Jobsite Alert from ${data.user.fullname}`,
                                description: data.message.body,
                                duration: 10,
                                placement: "topLeft",
                            });
                        },
                    }
                )
            );
        }
    }, [cableApp, currentProjectToken]);

    useEffect(() => {
        if (currentProjectInfo) {
            setDeliveriesChannels();
            loadThisWeekEvents(currentProjectInfo);
        }
    }, [currentTeamsProjects, currentProjectInfo]);

    const setDeliveriesChannels = () => {
        if (!cableApp) {
            return;
        }

        const channel: (actionCable.Channel & {
            received: (data: any) => void;
        })[] = [];

        // If currentProjectToken is not null then we are on a project page. Else, we're on a team page.
        if (currentProjectToken) {
            channel[currentProjectToken] = cableApp.subscriptions.create(
                {
                    channel: "DeliveriesChannel",
                    project_unique_token: currentProjectToken,
                },
                {
                    received: (data) => {
                        if (data.method == "update" && data.type == "delivery") {
                            loadThisWeekEvents(data.project);
                        }
                    },
                }
            );
        } else if (currentTeamsProjects) {
            const recent_project_ids = currentTeamsProjects?.recent_projects.map((project) => project.project_id);
            currentTeamsProjects?.member_of
                .filter((project) => recent_project_ids.includes(project.id))
                .forEach((project) => {
                    channel[project.unique_token] = cableApp.subscriptions.create(
                        {
                            channel: "DeliveriesChannel",
                            project_unique_token: project.unique_token,
                        },
                        {
                            received: (data) => {
                                if (data.method == "update" && data.type == "delivery") {
                                    loadThisWeekEvents(data.project);
                                }
                            },
                        }
                    );
                });
        }

        setDeliveriesChannel(channel);
    };

    const loadThisWeekEvents = (project: any) => {
        const today = new Date();
        const firstDayOfWeek = startOfWeek(today);
        const month = firstDayOfWeek.getMonth();
        const year = firstDayOfWeek.getFullYear();
        const projectToken = project?.unique_token || currentProjectToken;
        const projectSubdomain = project?.claimed_by_team_subdomain || "projects";

        PlotAPI.getCalendarsEventsPageForProject(projectSubdomain, projectToken, year, month)
            .then((response) => {
                return response.map((event) => CalendarEvent.fromAPIObject(event));
            })
            .then((calendarEvents) => {
                const weekEvents = new Array<{ day_index: number; event: CalendarEvent }>();

                calendarEvents.forEach((event) => {
                    if (isSameWeek(today, event.start)) {
                        weekEvents.push({ day_index: event.start.getDay(), event: event });
                    }
                });

                setThisWeeksEvents(weekEvents);
            });
    };

    const handleWindowSizeChange = () => {
        setWindowSize({ width: window.innerWidth, height: window.innerHeight });

        if (window.innerWidth < 992) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    };

    useEffect(() => {
        if (
            pageContentType === PageContentType.TEAM ||
            pageContentType == PageContentType.DELIVERY ||
            pageContentType == PageContentType.PROJECT
        ) {
            PlotAPI.getCurrentSubdomainTeam().then((response) => {
                _setCurrentViewedTeamInfo(new TeamInfo(response));
            });
        }
    }, [pageContentType]);

    // User Info Changed?
    useEffect(() => {
        if (userInfo) {
            PlotAPI.getCurrentUsersTeams().then((response) => {
                setUsersTeams(response?.map((team) => new TeamInfo(team)) || null);
            });
        } else {
            setUsersTeams(null);
        }
    }, [userInfo]);

    // Users Teams List Changed?
    useEffect(() => {
        if (usersTeams) {
            if (Helper.getCookie("team") === "" && usersTeams.length > 0) {
                setCurrentTeam(usersTeams[0].id);
            } else {
                const team_id = Helper.getCookie("team");
                setCurrentTeam(parseInt(team_id));
            }
        }
    }, [usersTeams]);

    // Current Team Changed?
    useEffect(() => {
        if (currentTeam) {
            reloadCurrentTeamsProjects();
        }
    }, [currentTeam]);

    // Current Project Token Changed?
    useEffect(() => {
        if (currentProjectToken) {
            PlotAPI.getProjectInfo(currentProjectToken).then((response) => {
                _setCurrentProjectInfo(response);
            });
            getAndSetProjectMapInfo(currentProjectToken);
        }
    }, [currentProjectToken]);

    /**
     * Updates User Info in the backend and then updates the context with it
     * @param userInfo New User Info to Update with
     * @returns boolean indicating success of the update
     */
    const setUserInfo = (userInfo): Promise<boolean> => {
        // TODO: Add some POST to update user info in backend, then get new data, and then call _setUserInfo
        return PlotAPI.putCurrentUserInfo(userInfo).then((response) => {
            if (response) {
                if (response.message === "Success") {
                    return PlotAPI.getCurrentUserInfo().then((response) => {
                        _setUserInfo(new UserInfo(response));

                        if (response) {
                            setLoggedIn(true);
                        } else {
                            setLoggedIn(false);
                        }

                        return true;
                    });
                } else {
                    notification.warning({
                        message: "Cannot update User Info",
                        description: response.message,
                        duration: globalAppConfig.NOTIFICATION_DURATION,
                    });
                    return false;
                }
            } else {
                return false;
            }
        });
    };

    /**
     * Set the current User's Profile Pic using a Data URL
     * @param imageAsDataURL URL containing the Data of the image to be uploaded
     * @returns boolean indicating success of the update
     */
    const setUserPic = (imageAsDataURL): Promise<boolean> => {
        return PlotAPI.postCurrentUserPic(imageAsDataURL).then((picUploadResponse) => {
            return PlotAPI.getCurrentUserPic().then((response) => {
                _setUserPic(response);

                return picUploadResponse;
            });
        });
    };

    const reloadUserInfo = (): Promise<boolean> => {
        return PlotAPI.getCurrentUserInfo().then((response) => {
            _setUserInfo(new UserInfo(response));
            return PlotAPI.getCurrentUserPic().then((response) => {
                _setUserPic(response);
                return Promise.resolve(true);
            });
        });
    };

    /**
     * Sets the current team by id by updating the cookie
     * @param team_id ID to set as the current team
     * @returns none
     */
    const setCurrentTeam = (team_id): void => {
        if (team_id && usersTeams) {
            const current_team = usersTeams.find((team) => team.id === team_id);

            if (current_team) {
                Helper.setCookie("team", current_team.id, 365);
                _setCurrentTeam(current_team);
                return;
            } else if (usersTeams.length > 0) {
                Helper.setCookie("team", usersTeams[0].id, 365);
                _setCurrentTeam(usersTeams[0]);
                return;
            }
        }

        _setCurrentTeam(null);
    };

    const reloadCurrentTeam = (): void => {
        if (!currentTeam) {
            return;
        }

        PlotAPI.getTeam(currentTeam.subdomain).then(() => {
            _setCurrentTeam(currentTeam);
        });
    };

    const setCurrentAppVersion = () => {
        fetch("/version.txt")
            .then((response) => response.text())
            .then((text) => _setCurrentAppVersion(text));
    };

    const reloadCurrentTeamsProjects = (): Promise<void> => {
        if (!currentTeam) {
            return Promise.resolve();
        }

        return PlotAPI.getProjectsOnTeam(currentTeam.subdomain).then((response) => {
            setCurrentTeamsProjects(response);
        });
    };

    /**
     * Reload the current project without reloading the page
     * @returns Boolean indicatinng success of reload
     */
    const reloadCurrentProject = (): Promise<boolean> => {
        if (currentProjectToken) {
            return PlotAPI.getProjectInfo(currentProjectToken).then((response) => {
                _setCurrentProjectInfo(response);

                return true;
            });
        }

        return Promise.resolve(true);
    };

    const reloadCurrentViewedTeamInfo = (): void => {
        PlotAPI.getCurrentSubdomainTeam().then((response) => {
            _setCurrentViewedTeamInfo(new TeamInfo(response));
        });
    };

    /**
     * Set the current project token, this will set off the chain of reloading the current project info and checking access level for permissions
     * @param token Token of the new project info to get
     */
    const setCurrentProjectToken = (token: string): void => {
        _setCurrentProjectToken(token);
    };

    /**
     * Get current weather for a project  jobsite
     * @returns weather if found, else null.
     */
    const getCurrentProjectWeather = (): any | null => {
        Weather.getWeatherInformation(`${currentProjectInfo.lat}, ${currentProjectInfo.lon}`, "forecast").then(
            (response) => {
                if (response.ok) {
                    setCurrentProjectWeather(response);
                } else {
                    Sentry.captureMessage("Error getting weather information" + response);
                }
            }
        );
    };

    const getAndSetProjectMapInfo = (project_token: string) => {
        PlotAPI.getProjectMapLayers(project_token, window.location.hostname.split(".")[0]).then((response) => {
            if (response.length > 0) {
                setProjectMapLayers(response);
            }
        });
    };

    useEffect(() => {
        if (currentProjectInfo) {
            Weather.getWeatherInformation(`${currentProjectInfo.lat}, ${currentProjectInfo.lon}`, "forecast").then(
                (response) => {
                    setCurrentProjectWeather(response);
                }
            );
        }
    }, [currentProjectInfo]);

    function setUserTheme(theme: string) {
        const body = document.querySelector("body");
        body?.setAttribute("data-theme", theme);
        // document.documentElement.classList.add("dark");
        localStorage.theme = theme;
        if (theme === "dark") {
            document.documentElement.classList.add("dark");
        } else {
            document.documentElement.classList.remove("dark");
        }
    }

    const reloadUsersTeams = () => {
        PlotAPI.getCurrentUsersTeams().then((response) => {
            setUsersTeams(response?.map((team) => new TeamInfo(team)) || null);
        });
    };

    const mapIcons = {
        equipmentIcons: [
            "barricade_black",
            "barricade_blue",
            "barricade_orange",
            "barricade_pink",
            "barricade_purple",
            "barricade_red",
            "barricade_turquoise",
            "barricade_yellow",
            "concrete_pump_black",
            "concrete_pump_blue",
            "concrete_pump_orange",
            "concrete_pump_pink",
            "concrete_pump_purple",
            "concrete_pump_red",
            "concrete_pump_turquoise",
            "concrete_pump_yellow",
            "crane_truck_black",
            "crane_truck_blue",
            "crane_truck_orange",
            "crane_truck_pink",
            "crane_truck_purple",
            "crane_truck_red",
            "crane_truck_turquoise",
            "crane_truck_yellow",
            "crawler_crane_black",
            "crawler_crane_blue",
            "crawler_crane_orange",
            "crawler_crane_pink",
            "crawler_crane_purple",
            "crawler_crane_red",
            "crawler_crane_turquoise",
            "crawler_crane_yellow",
            "fork_lift_black",
            "fork_lift_blue",
            "fork_lift_orange",
            "fork_lift_pink",
            "fork_lift_purple",
            "fork_lift_red",
            "fork_lift_turquoise",
            "fork_lift_yellow",
            "hook_black",
            "hook_blue",
            "hook_orange",
            "hook_pink",
            "hook_purple",
            "hook_red",
            "hook_turquoise",
            "hook_yellow",
            "office_trailer_black",
            "office_trailer_blue",
            "office_trailer_orange",
            "office_trailer_pink",
            "office_trailer_purple",
            "office_trailer_red",
            "office_trailer_turquoise",
            "office_trailer_yellow",
            "restroom_black",
            "restroom_blue",
            "restroom_orange",
            "restroom_pink",
            "restroom_purple",
            "restroom_red",
            "restroom_turquoise",
            "restroom_yellow",
            "skip_hoist_black",
            "skip_hoist_blue",
            "skip_hoist_orange",
            "skip_hoist_pink",
            "skip_hoist_purple",
            "skip_hoist_red",
            "skip_hoist_turquoise",
            "skip_hoist_yellow",
            "tower_crane_black",
            "tower_crane_blue",
            "tower_crane_orange",
            "tower_crane_pink",
            "tower_crane_purple",
            "tower_crane_red",
            "tower_crane_turquoise",
            "tower_crane_yellow",
        ],
        // standardIcons: [
        //     "barrier_std_icon",
        //     "exit_std_icon",
        //     "gate_std_icon",
        //     "jobsite_trailer_std_icon",
        //     "no-entry_std_icon",
        //     "portable_toilet_std_icon",
        // ],
        sdfIcons: [
            "arrow_sdf_icon",
            "left-chevron_sdf_icon",
            "left-double-chevron_sdf_icon",
            "right-chevron_sdf_icon",
            "right-double-chevron_sdf_icon",
            "two_way_sdf_icon_1",
            "two_way_sdf_icon_2",
            "barbed_wire_sdf_icon",
            "bullet_sdf_icon",
            "crane_sdf_icon",
            "delivery_truck_sdf_icon",
            "dumpster_sdf_icon",
            "lightning_sdf_icon",
            "map_marker_sdf_icon",
            "no-entry_sdf_icon",
            "no-parking_sdf_icon",
            "parking-sign_sdf_icon",
            "portable-toilet_sdf_icon",
            "road-barrier_sdf_icon",
            "warning_sdf_icon",
        ],
    };

    const userHasPermission = (permission: Permission, team: number, project: number | null) => {
        let loaded_projects_if_needed = false;
        if (project == null) {
            // Do need to ensure loaded
            loaded_projects_if_needed = true;
        } else if (currentProjectToken && project == currentProjectInfo?.id && currentProjectInfo) {
            // We want the viewed project and it is loaded
            loaded_projects_if_needed = true;
        } else if (currentTeamsProjects) {
            // We want any other project which should only be current users teams projects
            loaded_projects_if_needed = true;
        }

        let loaded_teams_if_needed = false;
        if (team == null) {
            // Checking explict null in case of team id 0
            loaded_teams_if_needed = true;
        } else if ((currentTeam && currentTeam.id == team) || currentTeam == null) {
            loaded_teams_if_needed = true;
        } else if (currentViewedTeamInfo && currentViewedTeamInfo.id == team) {
            loaded_teams_if_needed = true;
        }

        if (userInfo && loaded_projects_if_needed && loaded_teams_if_needed) {
            return userInfo.permission(permission, team, project);
        }
        return false;
    };

    return (
        <GlobalStoreContext.Provider
            value={{
                userInfo,
                setUserInfo,
                gottenUserInfo,
                userPic,
                setUserPic,
                reloadUserInfo,
                loggedIn,
                usersTeams,
                reloadUsersTeams,
                currentTeam,
                setCurrentTeam,
                reloadCurrentTeam,
                reloadCurrentTeamsProjects,
                currentTeamsProjects,
                pageContentType,
                setPageContentType,
                currentProjectToken,
                setCurrentProjectToken,
                currentProjectInfo,
                currentViewedTeamInfo,
                reloadCurrentProject,
                reloadCurrentViewedTeamInfo,
                windowSize,
                isMobile,
                cableApp,
                mapIcons,
                currentProjectWeather,
                getCurrentProjectWeather,
                userHasPermission,
                projectMapLayers,
                currentAppVersion,
                setCurrentAppVersion,
                dashboardNotificationsChannel,
                newBroadcastMessage,
                setNewBroadcastMessage,
                mapChannel,
                deliveriesChannel,
                setUserTheme,
                thisWeeksEvents,
                activeSidebarKey,
                setActiveSidebarKey,
                collapseSidebar,
                setCollapseSidebar,
                showNotificationsDrawer,
                setShowNotificationsDrawer,
            }}
        >
            {props.children}
        </GlobalStoreContext.Provider>
    );
}

export default GlobalStoreProvider;
